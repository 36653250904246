import axios from 'axios';
import React, { useEffect, useMemo, useState } from "react";
import { Link, withRouter, useLocation, useParams, useHistory } from "react-router-dom";
import html2pdf from 'html2pdf.js';


import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  Row,
  Table,
  Collapse,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";

const API_URL = process.env.REACT_APP_API_URL;

import selfie from "../../../assets/images/default-selfie.png"

//Import mini card widgets
import MiniCards from "./mini-card";

//Import Images
import profile1 from "assets/images/profile-img.png";

const ContactsProfile = (props) => {
  //meta title
  document.title = "Personal Details";

  const [personalInfoOpen, setPersonalInfoOpen] = useState(false);
  const [personalMediaOpen, setPersonalMediaOpen] = useState(false);

  const togglePersonalInfo = () => {
    setPersonalInfoOpen(!personalInfoOpen);
  };
  const togglePersonalMedia = () => {
    setPersonalMediaOpen(!personalMediaOpen);
  };

  const { id } = useParams();
  const [userProfile, setUserData] = useState(null);
  const storedDataString = localStorage.getItem("authUser");
  const storedData = JSON.parse(storedDataString);
  const accessToken = storedData.token || props.tokenValue;


  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${API_URL}/users/${id}`
        );
        const user = response.data;
        setUserData(user);
      } catch (error) {
        console.log(error);
      }
    };

    fetchUser();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');


    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  };

  function getStatusColor(status) {
    if (status === "NOT_STARTED") {
      return "danger"; // red color
    } else if (
      status === "INTRO_DONE" ||
      status === "PERSONAL_DETAILS_DONE" ||
      status === "IIFL_INFO_DONE"
    ) {
      return "warning"; // blue color
    } else if (status === "COMPLETED") {
      return "success"; // green color
    } else {
      return ""; // default color if status is not recognized
    }
  }


  const history = useHistory();

  const exportToPDF = (clientCode, applicantName) => {
    const input = document.getElementById('table-container');
    const headerContent = `
      <div style="text-align: center; font-size: 18px; margin-bottom: 10px;">
        Time Line Investments - Client Master data report
      </div>
    `;
  
    const inputDataWithBorders = `
      <div style="border: 1px solid #000; padding: 5px;">
        ${input.innerHTML}
      </div>
    `;
  
    const opt = {
      margin: [10, 15, 10, 15], // Adjust margins as needed
      filename: `${applicantName}_${clientCode}_info.pdf`,
      image: { type: 'png', quality: 1 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }, // Adjust format and orientation as needed
    };
  
    const fullContent = headerContent + inputDataWithBorders;
  
    html2pdf().from(fullContent).set(opt).save();
  };  

  const [currentDate, setCurrentDate] = useState('');

  useEffect(() => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = today.toLocaleString('en-US', { month: 'short' });
    const year = today.getFullYear();

    setCurrentDate(`${day}${month}${year}`);
  }, []);

  const handleExportPDF = () => {

    const clientCode = userProfile.userIntro.clientCode;
    const applicantName = userProfile.userIntro.applicantName;
    const formattedName = applicantName.replace(/\s/g, '').substring(0, 12);
    //const date = currentDate;
    exportToPDF(clientCode, formattedName);
  };

  const [modal, setModal] = useState(false);
  const [clickedImageUrl, setClickedImageUrl] = useState('');

  const toggle = () => {
    setModal(!modal);
  };

  const openImageModal = (imageUrl) => {
    setClickedImageUrl(imageUrl);
    toggle();
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState({
    selectAll: false,
    selfie: false,
    signature: false,
    aadhaarCard: false,
    panCard: false,
    bankAccount: false,
    cancelledCheque: false,
    sixMonthsBankStatement: false,
  });

  const [selectedImages, setSelectedImages] = useState([]);

  const toggleCheckbox = (checkboxName, image) => {
    //console.log('Checkbox clicked:', checkboxName);
    setSelectedCheckboxes({
      ...selectedCheckboxes,
      [checkboxName]: !selectedCheckboxes[checkboxName],
    });

    // Depending on your logic, you can add or remove the selected image
    if (!selectedCheckboxes[checkboxName]) {
      setSelectedImages([...selectedImages, image]);
    } else {
      setSelectedImages(selectedImages.filter((selectedImage) => selectedImage !== image));
    }
    const imageUrls = getSelectedImageUrls(selectedCheckboxes);
  };

  // Function to handle the "Select All" checkbox change event
  const handleSelectAllChange = () => {
    const selectAllChecked = !selectedCheckboxes.selectAll;

    // Create a new object with all checkboxes set to the same state
    const updatedCheckboxes = { ...selectedCheckboxes };

    for (const key in updatedCheckboxes) {
      updatedCheckboxes[key] = selectAllChecked;
    }

    setSelectedCheckboxes(updatedCheckboxes);
    const imageUrls = getSelectedImageUrls(updatedCheckboxes);


  };


  const downloadSelectedImages = () => {

    const selectedImageUrls = getSelectedImageUrls(selectedCheckboxes);
    //console.log(selectedImageUrls);

    const fetchImages = async () => {

      try {
        // Define your headers
        const response = await axios.patch(
          `${API_URL}/download-files-zipped`,
          {
            fileUrls: selectedImageUrls,
          },
          {
            headers: {
              uid: id,
              fileType: 'pan',
              'Authorization': accessToken,
              'Content-Type': 'application/json',
            },
            responseType: 'blob', // Specify the response type as a blob
          }
        );

        // Create a Blob object from the response data
        const zipBlob = new Blob([response.data], { type: 'application/zip' });

        // Create a temporary URL for the Blob
        const zipUrl = URL.createObjectURL(zipBlob);

        // Create a download link and trigger the download
        const a = document.createElement('a');
        a.href = zipUrl;
        a.download = 'images.zip'; // Set the desired filename for the download
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();

        // Clean up the URL and remove the anchor element
        URL.revokeObjectURL(zipUrl);
        document.body.removeChild(a);
      } catch (error) {
        console.log(error);
        // Handle errors here
      }
    };

    fetchImages();
  };

  const getSelectedImageUrls = (checkboxes) => {
    const imageUrls = [];

    if (checkboxes.selfie) {
      imageUrls.push(userProfile.userIIFLInfo.selfieImage);
    }

    if (checkboxes.signature) {
      imageUrls.push(userProfile.userIIFLInfo.signImage);
    }

    if (checkboxes.aadhaarCard && userProfile && userProfile.userPersonalDetails && userProfile.userPersonalDetails.poaAadhaarCardImage) {
      imageUrls.push(userProfile.userPersonalDetails.poaAadhaarCardImage);
    }
    

    if (checkboxes.panCard && userProfile && userProfile.userPersonalDetails && userProfile.userPersonalDetails.poiPanCardImage) {
      imageUrls.push(userProfile.userPersonalDetails.poiPanCardImage);
    }
    if (checkboxes.bankAccount && userProfile && userProfile.userPersonalDetails && userProfile.userPersonalDetails.bankAccountVerificationDocImage) {
      imageUrls.push(userProfile.userPersonalDetails.bankAccountVerificationDocImage);
    }
    if (checkboxes.cancelledCheque && userProfile && userProfile.userIIFLInfo && userProfile.userIIFLInfo.cancelledChequeImage) {
      imageUrls.push(userProfile.userIIFLInfo.cancelledChequeImage);
    }
    if (checkboxes.sixMonthsBankStatement) {
      imageUrls.push(userProfile.userIIFLInfo.sixMonthsBankStatementPDF);
    }
    // Add more conditions as needed for other checkboxes

    return imageUrls;
  };


  const [pageNumbers, setPageNumbers] = useState([]); // Initialize the state for input value

  const handlePdfDownload = () => {

    const downloadPDF = async () => {

      try {
        // Define your headers
        const pageNumbersArray = pageNumbers.split(',').map(Number);
        //console.log(pageNumbersArray)

        fetch(`${API_URL}/bank-statement/custom-pdf`, {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': accessToken,
            // Add any other headers if needed
          },
          body: JSON.stringify({
            "uid": id,
            "pageNumbers": pageNumbersArray
          })
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error(`Network response was not ok: ${response.status}`);
            }
            return response.blob();
          })
          .then((pdfBlob) => {
            autoDownloadPDF(pdfBlob);
          })
          .catch((error) => {
            console.error('Error downloading PDF:', error);
          });
        /////
      } catch (error) {
        console.log(error);
        // Handle errors here
      }
    };

    downloadPDF();
  };

  function autoDownloadPDF(pdfData) {
    // Create a Blob from the binary data
    const blob = new Blob([pdfData], { type: 'application/pdf' });

    // Create a URL for the Blob
    const url = window.URL.createObjectURL(blob);

    // Create a hidden link element
    const link = document.createElement('a');
    link.style.display = 'none';
    link.href = url;
    link.download = 'downloaded.pdf'; // Specify the desired filename

    // Append the link to the document body
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Clean up by removing the link element and revoking the URL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  }

  const [modal1, setModal1] = useState(false);

  const toggleViewModal = () => setModal1(!modal1);

  const [pageNumber, setPageNumber] = useState(1);

  const handlePreview = () => {
    setModal1(true);
  };

  const handleDownloadClick = (name, imageUrl) => {
    fetch(imageUrl)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', name + '.jpg');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <button className="btn btn-light" onClick={() => history.goBack()}>
          Back
        </button>
        {userProfile ? (
          <Container fluid>
            {/* Render Breadcrumbs */}
            {/* <Breadcrumbs title="Contacts" breadcrumbItem="Profile" /> */}
            <Row>
              <Col xl="6">
                <Card className="overflow-hidden">
                  <div className="bg-primary bg-soft">
                    <Row>
                      <Col xs="7">
                        <div className="text-primary p-3">
                          <h5 className="text-primary">Welcome Back !</h5>
                          <p>It will seem like simplified</p>
                        </div>
                      </Col>
                      <Col xs="5" className="align-self-end">
                        <img src={profile1} alt="" className="img-fluid" />
                      </Col>
                    </Row>
                  </div>
                  <CardBody className="pt-0">
                    <Row>
                      <Col sm="4">
                        <div className="avatar-md profile-user-wid mb-4">
                          {userProfile && userProfile.userIIFLInfo && userProfile.userIIFLInfo.selfieImage ? (
                            <img src={userProfile.userIIFLInfo.selfieImage} alt="" className="img-thumbnail rounded-circle" />
                          ) : (
                            <span>
                              <img src={selfie} alt="" className="img-thumbnail rounded-circle" />
                            </span>
                          )}
                        </div>
                      </Col>

                      <Col sm={8}>
                        <div className="pt-4">
                          <Row>
                            <Col xs="6">
                              <h5 className="font-size-15">Name</h5>
                              <p className="text-muted mb-2">{userProfile?.userIntro?.applicantName || 'N/A'}</p>
                              <h5 className="font-size-15">Client Id</h5>
                              <p className="text-muted mb-0">{userProfile?.userIntro?.clientCode || 'N/A'}</p>
                            </Col>
                            {/* <Col xs="6">
                              <h5 className="font-size-15">Client Code</h5>
                              <p className="text-muted mb-0">{userProfile?.userIntro?.clientCode || 'N/A'}</p>
                            </Col> */}
                          </Row>
                        </div>
                      </Col>
                    </Row>

                  </CardBody>
                </Card>
                <Row>
                  <Col sm="6">
                    <Button
                      color="primary"
                      onClick={togglePersonalMedia}
                      className="mb-3 has-arrow"
                      style={{ position: "relative", paddingRight: "30px", marginRight: "20px" }}
                    >
                      Media
                      <i
                        className="bx bx-chevron-down arrow-icon"
                        style={{
                          fontSize: "18px",
                          marginLeft: "8px",
                          transition: "transform 0.3s ease",
                          transform: personalMediaOpen ? "rotate(180deg)" : "rotate(0)",
                          position: "absolute",
                          top: "25%",
                          right: "10px",
                          transformOrigin: "center",
                        }}
                      ></i>
                    </Button>
                  </Col>
                </Row>
                <Card>

                  <Collapse isOpen={personalMediaOpen}>
                    <CardBody>
                      {/* <CardTitle className="mb-4">User IIFL Info</CardTitle> */}

                      <div className="table-responsive">
                        <Table className="table-nowrap mb-0">
                          <tbody>
                            <tr>
                              <th>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedCheckboxes.selectAll}
                                  onClick={handleSelectAllChange}
                                  style={{ borderColor: "blue", marginRight: "6px" }}
                                />
                                Select All
                              </th>
                              <td>
                                <Button color="primary" className="mb-2" onClick={downloadSelectedImages}>
                                  Download Selected files
                                </Button>
                              </td>
                            </tr>
                            <tr>
                              {/* <th scope="row">1. Selfie Photo :</th> */}
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedCheckboxes.selfie}
                                  onClick={() => toggleCheckbox('selfie', userProfile.userIIFLInfo.selfieImage)}
                                  style={{ borderColor: "blue", marginRight: "6px" }}
                                />
                                1. Selfie Photo :
                              </th>
                              <td>
                                {userProfile && userProfile.userIIFLInfo && userProfile.userIIFLInfo.selfieImage ? (
                                  <div className="image-container">
                                    <img
                                      src={userProfile.userIIFLInfo.selfieImage}
                                      alt=""
                                      className="img-thumbnail rounded-circle"
                                      style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                                      onClick={() => openImageModal(userProfile.userIIFLInfo.selfieImage)}
                                    />
                                    <div className="icons">
                                      <a onClick={() => handleDownloadClick('selfie', userProfile.userIIFLInfo.selfieImage)} className='text-success'>
                                        <i className="fas fa-download" style={{ marginRight: 5 }}></i>
                                        Download
                                      </a>
                                      <a href="#" onClick={() => openImageModal(userProfile.userIIFLInfo.selfieImage)}>
                                        <i
                                          className="fas fa-expand"
                                          style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }}
                                        ></i>
                                        <span className="tooltip-text">Expand</span>
                                      </a>

                                    </div>
                                  </div>
                                ) : (
                                  <span>not available</span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              {/* <th scope="row">2. Signature Photo :</th> */}
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedCheckboxes.signature}
                                  onClick={() => toggleCheckbox('signature', userProfile.userIIFLInfo.signImage)}
                                  style={{ borderColor: "blue", marginRight: "6px" }}
                                />
                                2. Signature Photo :</th>
                              <td>
                                {userProfile && userProfile.userIIFLInfo && userProfile.userIIFLInfo.signImage ? (
                                  <div className="image-container">
                                    <img
                                      src={userProfile.userIIFLInfo.signImage}
                                      alt=""
                                      className="img-thumbnail relative-position"
                                      style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                                      onClick={() => openImageModal(userProfile.userIIFLInfo.signImage)}
                                    />
                                    <div className="icons">
                                    <a onClick={() => handleDownloadClick('signature', userProfile.userIIFLInfo.signImage)} className='text-success'>
                                        <i className="fas fa-download" style={{ marginRight: 5 }}></i>
                                        Download
                                      </a>
                                      <a href="#" onClick={() => openImageModal(userProfile.userIIFLInfo.signImage)}>
                                        <i
                                          className="fas fa-expand"
                                          style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }}
                                        ></i>
                                        <span className="tooltip-text">Expand</span>
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <span>not available</span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className='text-wrap'> <input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedCheckboxes.aadhaarCard}
                                onClick={() => toggleCheckbox('aadhaarCard', userProfile.userPersonalDetails.poaAadhaarCardImage)}
                                style={{ borderColor: "blue", marginRight: "6px" }}
                              />3. Aadhaar Card Photo:</th>
                              <td>
                                {userProfile.userPersonalDetails?.poaAadhaarCardImage ? (
                                  // <img src={userProfile.userPersonalDetails.poaAadhaarCardImage} alt="" className="img-thumbnail" style={{ objectFit: 'contain', width: '100%', height: 'auto' }} onClick={() => openImageModal(userProfile.userPersonalDetails.poaAadhaarCardImage)} />
                                  <div className="image-container">
                                    <img
                                      src={userProfile.userPersonalDetails.poaAadhaarCardImage}
                                      alt=""
                                      className="img-thumbnail relative-position"
                                      style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                                      onClick={() => openImageModal(userProfile.userPersonalDetails.poaAadhaarCardImage)}
                                    />
                                    <div className="icons">
                                    <a onClick={() => handleDownloadClick('aadhaarCard', userProfile.userPersonalDetails.poaAadhaarCardImage)} className='text-success'>
                                        <i className="fas fa-download" style={{ marginRight: 5 }}></i>
                                        Download
                                      </a>
                                      <a href="#" onClick={() => openImageModal(userProfile.userPersonalDetails.poaAadhaarCardImage)}>
                                        <i
                                          className="fas fa-expand"
                                          style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }}
                                        ></i>
                                        <span className="tooltip-text">Expand</span>
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  'not available'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedCheckboxes.panCard}
                                  onClick={() => toggleCheckbox('panCard', userProfile.userPersonalDetails.poiPanCardImage)}
                                  style={{ borderColor: "blue", marginRight: "6px" }}
                                />
                                4. Pan Card Photo :
                              </th>
                              <td>
                                {userProfile.userPersonalDetails?.poiPanCardImage ? (
                                  <div className="image-container">
                                    <img
                                      src={userProfile.userPersonalDetails.poiPanCardImage}
                                      alt=""
                                      className="img-thumbnail relative-position"
                                      style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                                      onClick={() => openImageModal(userProfile.userPersonalDetails.poiPanCardImage)}
                                    />
                                    <div className="icons">
                                      <a onClick={() => handleDownloadClick('panCard', userProfile.userPersonalDetails.poiPanCardImage)} className="text-success">
                                        <i className="fas fa-download" style={{ marginRight: 5 }}></i>
                                        Download
                                      </a>
                                      <a href="#" onClick={() => openImageModal(userProfile.userPersonalDetails.poiPanCardImage)}>
                                        <i
                                          className="fas fa-expand"
                                          style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }}
                                        ></i>
                                        <span className="tooltip-text">Expand</span>
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  'not available'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedCheckboxes.bankAccount}
                                  onClick={() => toggleCheckbox('bankAccount', userProfile.userPersonalDetails.bankAccountVerificationDocImage)}
                                  style={{ borderColor: "blue", marginRight: "6px" }}
                                />
                                5. Bank Account Photo :</th>
                              <td>
                                {userProfile.userPersonalDetails?.bankAccountVerificationDocImage ? (
                                  // <img src={userProfile.userPersonalDetails.bankAccountVerificationDocImage} alt="" className="img-thumbnail" onClick={() => openImageModal(userProfile.userPersonalDetails.bankAccountVerificationDocImage)} />
                                  <div className="image-container">
                                    <img
                                      src={userProfile.userPersonalDetails.bankAccountVerificationDocImage}
                                      alt=""
                                      className="img-thumbnail relative-position"
                                      style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                                      onClick={() => openImageModal(userProfile.userPersonalDetails.bankAccountVerificationDocImage)}
                                    />
                                    <div className="icons">
                                    <a onClick={() => handleDownloadClick('bankAccount', userProfile.userPersonalDetails.bankAccountVerificationDocImage)} className='text-success'>
                                        <i className="fas fa-download" style={{ marginRight: 5 }}></i>
                                        Download
                                      </a>
                                      <a href="#" onClick={() => openImageModal(userProfile.userPersonalDetails.bankAccountVerificationDocImage)}>
                                        <i
                                          className="fas fa-expand"
                                          style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }}
                                        ></i>
                                        <span className="tooltip-text">Expand</span>
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  'not available'
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className='text-wrap'>
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={selectedCheckboxes.cancelledCheque}
                                  onClick={() => toggleCheckbox('cancelledCheque', userProfile.userIIFLInfo.cancelledChequeImage)}
                                  style={{ borderColor: "blue", marginRight: "6px" }}
                                />
                                6. Cancelled Cheque Photo :</th>
                              <td>
                                {userProfile && userProfile.userIIFLInfo && userProfile.userIIFLInfo.cancelledChequeImage ? (
                                  // <img src={userProfile.userIIFLInfo.cancelledChequeImage} alt="" className="img-thumbnail" onClick={() => openImageModal(userProfile.userIIFLInfo.cancelledChequeImage)} />
                                  <div className="image-container">
                                    <img
                                      src={userProfile.userIIFLInfo.cancelledChequeImage}
                                      alt=""
                                      className="img-thumbnail relative-position"
                                      style={{ objectFit: 'contain', width: '100%', height: 'auto' }}
                                      onClick={() => openImageModal(userProfile.userIIFLInfo.cancelledChequeImage)}
                                    />
                                    <div className="icons">
                                    <a onClick={() => handleDownloadClick('cancelledCheque', userProfile.userIIFLInfo.cancelledChequeImage)} className='text-success'>
                                        <i className="fas fa-download" style={{ marginRight: 5 }}></i>
                                        Download
                                      </a>
                                      <a href="#" onClick={() => openImageModal(userProfile.userIIFLInfo.cancelledChequeImage)}>
                                        <i
                                          className="fas fa-expand"
                                          style={{ marginLeft: 10, marginRight: 10, cursor: 'pointer' }}
                                        ></i>
                                        <span className="tooltip-text">Expand</span>
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <span>not available</span>
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th scope="row" className='text-wrap'><input
                                type="checkbox"
                                className="form-check-input"
                                checked={selectedCheckboxes.sixMonthsBankStatement}
                                onClick={() => toggleCheckbox('sixMonthsBankStatement', userProfile.userIIFLInfo.sixMonthsBankStatementPDF)}
                                style={{ borderColor: "blue", marginRight: "6px" }}
                              />
                                7. Six Months BankStatement PDF :

                              </th>
                              <td>
                                {userProfile && userProfile.userIIFLInfo && userProfile.userIIFLInfo.sixMonthsBankStatementPDF !== undefined ? (
                                  <div>
                                    <div style={{ display: 'flex'}}>
                                      <Input
                                        type='text'
                                        placeholder='e.g 1,2,3...'
                                        style={{ width: 80, marginRight: '3px' }}
                                        value={pageNumbers}
                                        onChange={(e) => setPageNumbers(e.target.value)}
                                      />
                                      <Button color='primary' onClick={handlePdfDownload}>Download</Button>
                                    </div>
                                    <Button color="primary" onClick={handlePreview} style={{ margin: "3px" }}>Preview PDF</Button>
                                    <Modal isOpen={modal1} toggle={toggleViewModal}>
                                      <ModalHeader toggle={toggleViewModal} tag="h4">
                                      </ModalHeader>
                                      <ModalBody>
                                        <iframe
                                          title="PDF Preview"
                                          src={userProfile.userIIFLInfo.sixMonthsBankStatementPDF}
                                          style={{ width: '100%', height: '500px', border: 'none' }}
                                        />
                                      </ModalBody>
                                    </Modal>
                                  </div>
                                ) : (
                                  <span>not available</span>
                                )}
                              </td>

                            </tr>

                          </tbody>
                        </Table>
                      </div>
                    </CardBody>
                  </Collapse>
                </Card>
              </Col>
              <Col xl="6">
                <Row>
                  {userProfile && userProfile.userIntro && userProfile.userIntro.planSelected !== undefined && (
                    <MiniCards
                      title="Plan"
                      text={userProfile.userIntro.planSelected}
                    // iconClass={card.iconClass}
                    />
                  )}
                  {userProfile && userProfile.status !== undefined && (
                    <MiniCards
                      title="Status"
                      text={userProfile.status}
                      iconClass={getStatusColor(userProfile.status)}
                      color={getStatusColor(userProfile.status)}
                    />
                  )}
                </Row>

                <Col xl="12">
                  <Row>
                    <Col sm="6">
                      <Button
                        color="primary"
                        onClick={togglePersonalInfo}
                        className="mb-3 has-arrow"
                        style={{ position: "relative", paddingRight: "30px", marginRight: "20px" }}
                      >
                        Data
                        <i
                          className="bx bx-chevron-down arrow-icon"
                          style={{
                            fontSize: "18px",
                            marginLeft: "8px",
                            transition: "transform 0.3s ease",
                            transform: personalInfoOpen ? "rotate(180deg)" : "rotate(0)",
                            position: "absolute",
                            top: "25%",
                            right: "10px",
                            transformOrigin: "center",
                          }}
                        ></i>
                      </Button>
                      <Button
                        color="primary"
                        onClick={handleExportPDF}
                        className="mb-3"
                      >
                        Download PDF
                      </Button>
                    </Col>
                  </Row>
                  <Collapse isOpen={personalInfoOpen}>
                    <Card>
                      <CardBody>
                        {/* <CardTitle className="mb-4">Personal Information</CardTitle>
                    <p className="text-muted mb-4">
                      {userProfile.personalDetail}
                    </p> */}
                        <div className="table-responsive" id="table-container" style={{ maxWidth: '100%', overflowX: 'auto'}}>
                          <Table className="table-nowrap mb-0">
                            <tbody>
                              <tr>
                                <th scope="row">1. Full Name :</th>
                                <td>{userProfile.userIntro?.applicantName || 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">2. Email :</th>
                                <td>{userProfile.userIntro?.email || 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">3. Selected Referrer :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userIntro?.selectedReferrer || 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">4. Contact Number :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal' }}>{userProfile.userPersonalDetails?.contactNumber || 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">5. Alternate Contact Number :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.alternativeContactNumber || 'N/A'}</td>
                              </tr>

                              <tr>
                                <th scope="row">6. DOB :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.dob ? formatDate(userProfile.userPersonalDetails.dob) : 'N/A'}</td>

                              </tr>
                              <tr>
                                <th scope="row">7. Guardian Name :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.guardianName || 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">8. Guardian Number :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.guardianNumber || 'N/A'}</td>
                              </tr>

                              <tr>
                                <th scope="row">9. GENDER :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.gender ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">10. Marital Status :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.maritalStatus ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">11. Father Spouse Name :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.fatherSpouseName ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">12. Pan Card Number :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.panCardNumber ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">13. Aadhaar Card Number :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.aadhaarCardNumber ?? 'N/A'}</td>
                              </tr>

                              <tr>
                                <th scope="row">14 .Address :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.fullAddress ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">15. District :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.district ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">16. State :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.state ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">17. Pincode :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.pincode ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">18. Bank Account Number :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.bankAccountNumber ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">19. Capital Invested :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.capitalInvested ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">20. Return Terms :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.returnTerms ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">21. Bank IFSC Code :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.bankIFSCCode ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">22. Nominee Name :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.nomineeName ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">23. Nominee Contact Number :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>{userProfile.userPersonalDetails?.nomineeContactNumber ?? 'N/A'}</td>
                              </tr>
                              <tr>
                                <th scope="row">24. Bank Statement Password :</th>
                                <td style={{ wordWrap: 'break-word', whiteSpace: 'normal'}}>
                                  {userProfile.userIIFLInfo?.bankStatementPassword ?? 'N/A'}
                                </td>
                              </tr>

                            </tbody>
                          </Table>
                        </div>
                      </CardBody>
                    </Card>
                  </Collapse>
                </Col>
                <Modal isOpen={modal} toggle={toggle}>
                  <ModalHeader toggle={toggle} tag="h4">
                    {/* Add header content if needed */}
                  </ModalHeader>
                  <ModalBody>
                    <img src={clickedImageUrl} alt="" style={{ width: "100%" }} />
                  </ModalBody>
                </Modal>
              </Col>
            </Row>
          </Container>
        ) : (
          <p>User not found</p>
        )}
      </div>
    </React.Fragment>
  );
};

export default ContactsProfile;
